import React, { Component } from 'react'
import { graphql } from 'gatsby'
import './index.scss'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'

import Layout from '../../components/Layout'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import SEO from '../../components/SEO'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { navigate } from 'gatsby'
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage'

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

class FreebyPage extends Component {
    constructor(props) {
        super(props)
        this.state = { isValidated: false }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
        e.preventDefault()
        e.stopPropagation()
        const form = e.currentTarget

        if (form.checkValidity() === true) {
            fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                    'form-name': form.getAttribute('name'),
                    ...this.state,
                }),
            })
                .then(() => navigate(form.getAttribute('action')))
                .catch(error => alert(error))
        }
        this.setState({ isValidated: true })
    }

    render() {
        return (
            <Layout location={this.props.location}>
                <SEO 
                    title='Freeby'
                    description='In nur drei Schritten zu deinem handgezeichneten Tierportrait von TIERGETREU. '
                />
                <div className='freeby-page'>
                    <div className='freeby-header bg-tint-muted'>
                        <Container className='container-freeby-header text-light'>
                            {/* Only show above lg (only desktop). */}
                            <h1 className='w-75 mx-auto mb-3 text-center d-none d-lg-block'>Hol dir deine Gratis-Skizze!</h1>
                            <PreviewCompatibleImage
                                className='freeby-header-image'
                                imageInfo={{ image: this.props.data.headerImageMobile.localFile }}
                                role='presentation'
                                alt=''
                                loading='eager'
                                fadeIn={false}
                            />
                        </Container>
                    </div>
                    <Container className='mb-5'>
                        <Row>
                            <Col className='col-text'>
                                {/* Only show up to lg (only mobile). */}
                                <h1 className='h2 w-75 mx-auto mb-5 text-center d-block d-lg-none'>Hol dir deine Gratis&#x2011;Skizze!</h1>
                                {/* Only show above lg (only desktop). */}
                                <div className='h2 mb-4 d-none d-lg-block'>Einfach ausdrucken</div>
                                <p>
                                    Dir gefällt die Idee, dich selbst oder einen genauso tierverliebten,
                                    lieben Menschen zu beschenken? Bist dir aber noch nicht sicher, wie das Ganze auf
                                    Papier wirkt?
                                </p>
                                <p>
                                    Kein Problem. Schick mir ein Foto deines Vierbeiners und ich fertige für dich eine
                                    Skizze an. Absolut kostenlos. Die Skizze kannst du dann zu Hause ausdrucken.
                                </p>
                                <p className='text-center text-lg-left'>
                                    Überzeug dich einfach selbst!
                                </p>
                            </Col>
                            <Col xs={12} lg='auto' className='col-form'>
                                <Jumbotron>
                                    <h2 className='mb-4'>Gratis-Skizze anfordern</h2>
                                    <Form
                                        noValidate
                                        validated={this.state.isValidated}
                                        name='contact-freeby'
                                        method='post'
                                        action='/gratis-skizze/danke/'
                                        data-netlify='true'
                                        data-netlify-honeypot='bot-field'
                                        onSubmit={this.handleSubmit}
                                    >
                                        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                                        <input type='hidden' name='form-name' value='contact-freeby' />
                                        <div hidden>
                                            <label>
                                                Don’t fill this out:{' '}
                                                <input name='bot-field' onChange={this.handleChange} />
                                            </label>
                                        </div>
                                        <Form.Row>
                                            <Form.Group as={Col} xs={12} lg={6}>
                                                <Form.Label className='label' htmlFor={'name'}>
                                                    Dein Name
                                                        </Form.Label>
                                                <Form.Control
                                                    type={'text'}
                                                    name={'name'}
                                                    onChange={this.handleChange}
                                                    id={'name'}
                                                    required={true}
                                                />
                                                <Form.Text className='text-muted'>
                                                    * Pflichtfeld
                                                </Form.Text>
                                                <Form.Control.Feedback type='invalid'>
                                                    Gib bitte deinen Namen an.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} xs={12} lg={6}>
                                                <Form.Label htmlFor={'email'}>
                                                    Deine E-Mail
                                                </Form.Label>
                                                <Form.Control
                                                    className='input'
                                                    type={'email'}
                                                    name={'email'}
                                                    onChange={this.handleChange}
                                                    id={'email'}
                                                    required={true}
                                                />
                                                <Form.Text className='text-muted'>
                                                    * Pflichtfeld
                                                </Form.Text>
                                                <Form.Control.Feedback type='invalid'>
                                                    Gib bitte deine E-Mail-Adresse an.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label htmlFor={'message'}>
                                                    Nachricht
                                                </Form.Label>
                                                <Form.Control
                                                    as='textarea'
                                                    rows='5'
                                                    name={'message'}
                                                    onChange={this.handleChange}
                                                    id={'message'}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className='mt-4 mb-2'>
                                            <Form.Group as={Col}>
                                                <Form.Check
                                                    required
                                                    label='Ich stimme zu, dass meine Angaben aus dem Formular zur Beantwortung meiner
                                                    Anfrage erhoben und verarbeitet werden. Detaillierte Informationen zum
                                                    Widerrufsrecht und zum Umgang mit Nutzerdaten finden sich in
                                                    der Datenschutzerklärung.'
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col xs={12}>
                                                <Button className='w-100' type='submit'>
                                                    Senden
                                                </Button>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </Jumbotron>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        )
    }
}

export default FreebyPage
export const pageQuery = graphql`
    query FreebyPageQuery {
        headerImageMobile: contentfulAsset(contentful_id: { eq: "2jH9UxrlZrHuwbbt3oCRLH" }) {
            title
            localFile {
                childImageSharp {
                    fluid(maxWidth: 768, pngQuality: 90) {
                        ...GatsbyImageSharpFluid_noBase64
                        src
                    }
                }
                publicURL
            }
        }
    }
`
